import { default as index0RTyFVSjicMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/[id]/index.vue?macro=true";
import { default as indexVqBjzxsxagMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/index.vue?macro=true";
import { default as _91imagem_93IPxsw1brOvMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/[imagem].vue?macro=true";
import { default as indexrtSbuaOPOpMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/index.vue?macro=true";
import { default as indexi4IYJjnAvjMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/index.vue?macro=true";
import { default as index0HmI0rjHMIMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/index.vue?macro=true";
import { default as indexMnfnWrdAvnMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/[id]/index.vue?macro=true";
import { default as indexRE2NP1CEydMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/index.vue?macro=true";
import { default as indexwZClUnGmZAMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/index.vue?macro=true";
import { default as index9ZYBCP6NwSMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/leads/[id]/index.vue?macro=true";
import { default as index2eisarla5WMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/leads/index.vue?macro=true";
import { default as indexAyppA5w16IMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/login/index.vue?macro=true";
import { default as indexCEzI94VbL5Meta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/[id]/index.vue?macro=true";
import { default as indexHA0M8povAiMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/index.vue?macro=true";
import { default as indexyW4Qsw92JsMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/index.vue?macro=true";
import { default as _91tipo_93uEXWOIAvYSMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue?macro=true";
import { default as indexWRmmacQBeNMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/index.vue?macro=true";
import { default as _91vantagem_93gADl11kLyfMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue?macro=true";
import { default as indexuoa5T9JtEKMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/index.vue?macro=true";
import { default as index9WdErFKKJNMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/index.vue?macro=true";
import { default as indexq6apE9Y5hhMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/[id]/index.vue?macro=true";
import { default as indexnKWd0XGSpcMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/index.vue?macro=true";
import { default as agradecimento9vailFkGnkMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/agradecimento.vue?macro=true";
import { default as _91slug_93jrf24XsSQoMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/[slug].vue?macro=true";
import { default as indexxSyGlWFrCvMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/index.vue?macro=true";
import { default as _91slug_93UjUuS3hKf9Meta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/[slug].vue?macro=true";
import { default as indexMcSS9O03fBMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/index.vue?macro=true";
import { default as contatoSNZ4hRVkQRMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/contato.vue?macro=true";
import { default as franquia752DSa3noQMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/franquia.vue?macro=true";
import { default as indexymdqFsxZKTMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/index.vue?macro=true";
import { default as _91slug_93i8BivprgfmMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/[slug].vue?macro=true";
import { default as index9FAc5ZNkmWMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/index.vue?macro=true";
import { default as privacidadeV2bHhAubV0Meta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/privacidade.vue?macro=true";
import { default as sobreiy9SnKOpQlMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/sobre.vue?macro=true";
import { default as _91slug_93bol7f8j5BQMeta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/[slug].vue?macro=true";
import { default as indexldjP9Fovj3Meta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/index.vue?macro=true";
import { default as component_45stubjibNALK6J9Meta } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubjibNALK6J9 } from "C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "admin-banners-id___pt",
    path: "/admin/banners/:id()",
    meta: index0RTyFVSjicMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-banners-id___es",
    path: "/es/admin/banners/:id()",
    meta: index0RTyFVSjicMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-banners___pt",
    path: "/admin/banners",
    meta: indexVqBjzxsxagMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-banners___es",
    path: "/es/admin/banners",
    meta: indexVqBjzxsxagMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/banners/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica-imagens-imagem___pt",
    path: "/admin/clinicas/:clinica()/imagens/:imagem()",
    meta: _91imagem_93IPxsw1brOvMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/[imagem].vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica-imagens-imagem___es",
    path: "/es/admin/clinicas/:clinica()/imagens/:imagem()",
    meta: _91imagem_93IPxsw1brOvMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/[imagem].vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica-imagens___pt",
    path: "/admin/clinicas/:clinica()/imagens",
    meta: indexrtSbuaOPOpMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica-imagens___es",
    path: "/es/admin/clinicas/:clinica()/imagens",
    meta: indexrtSbuaOPOpMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/imagens/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica___pt",
    path: "/admin/clinicas/:clinica()",
    meta: indexi4IYJjnAvjMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas-clinica___es",
    path: "/es/admin/clinicas/:clinica()",
    meta: indexi4IYJjnAvjMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/[clinica]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas___pt",
    path: "/admin/clinicas",
    meta: index0HmI0rjHMIMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-clinicas___es",
    path: "/es/admin/clinicas",
    meta: index0HmI0rjHMIMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/clinicas/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-depoimentos-id___pt",
    path: "/admin/depoimentos/:id()",
    meta: indexMnfnWrdAvnMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-depoimentos-id___es",
    path: "/es/admin/depoimentos/:id()",
    meta: indexMnfnWrdAvnMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-depoimentos___pt",
    path: "/admin/depoimentos",
    meta: indexRE2NP1CEydMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-depoimentos___es",
    path: "/es/admin/depoimentos",
    meta: indexRE2NP1CEydMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/depoimentos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexwZClUnGmZAMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-leads-id___pt",
    path: "/admin/leads/:id()",
    meta: index9ZYBCP6NwSMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/leads/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-leads-id___es",
    path: "/es/admin/leads/:id()",
    meta: index9ZYBCP6NwSMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/leads/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-leads",
    path: "/admin/leads",
    meta: index2eisarla5WMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/leads/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login___pt",
    path: "/admin/login",
    meta: indexAyppA5w16IMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/login/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-login___es",
    path: "/es/admin/login",
    meta: indexAyppA5w16IMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/login/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-posts-id___pt",
    path: "/admin/posts/:id()",
    meta: indexCEzI94VbL5Meta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-posts-id___es",
    path: "/es/admin/posts/:id()",
    meta: indexCEzI94VbL5Meta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-posts___pt",
    path: "/admin/posts",
    meta: indexHA0M8povAiMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-posts___es",
    path: "/es/admin/posts",
    meta: indexHA0M8povAiMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento___pt",
    path: "/admin/tratamentos/:tratamento()",
    meta: indexyW4Qsw92JsMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento___es",
    path: "/es/admin/tratamentos/:tratamento()",
    meta: indexyW4Qsw92JsMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-tipos-tipo___pt",
    path: "/admin/tratamentos/:tratamento()/tipos/:tipo()",
    meta: _91tipo_93uEXWOIAvYSMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-tipos-tipo___es",
    path: "/es/admin/tratamentos/:tratamento()/tipos/:tipo()",
    meta: _91tipo_93uEXWOIAvYSMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/[tipo].vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-tipos___pt",
    path: "/admin/tratamentos/:tratamento()/tipos",
    meta: indexWRmmacQBeNMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-tipos___es",
    path: "/es/admin/tratamentos/:tratamento()/tipos",
    meta: indexWRmmacQBeNMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/tipos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-vantagens-vantagem___pt",
    path: "/admin/tratamentos/:tratamento()/vantagens/:vantagem()",
    meta: _91vantagem_93gADl11kLyfMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-vantagens-vantagem___es",
    path: "/es/admin/tratamentos/:tratamento()/vantagens/:vantagem()",
    meta: _91vantagem_93gADl11kLyfMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/[vantagem].vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-vantagens___pt",
    path: "/admin/tratamentos/:tratamento()/vantagens",
    meta: indexuoa5T9JtEKMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos-tratamento-vantagens___es",
    path: "/es/admin/tratamentos/:tratamento()/vantagens",
    meta: indexuoa5T9JtEKMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/[tratamento]/vantagens/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos___pt",
    path: "/admin/tratamentos",
    meta: index9WdErFKKJNMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-tratamentos___es",
    path: "/es/admin/tratamentos",
    meta: index9WdErFKKJNMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/tratamentos/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-usuarios-id___pt",
    path: "/admin/usuarios/:id()",
    meta: indexq6apE9Y5hhMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-usuarios-id___es",
    path: "/es/admin/usuarios/:id()",
    meta: indexq6apE9Y5hhMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-usuarios___pt",
    path: "/admin/usuarios",
    meta: indexnKWd0XGSpcMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-usuarios___es",
    path: "/es/admin/usuarios",
    meta: indexnKWd0XGSpcMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/admin/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: "agradecimento___pt",
    path: "/agradecimento",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/agradecimento.vue").then(m => m.default || m)
  },
  {
    name: "agradecimento___es",
    path: "/es/agradecimento",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/agradecimento.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___pt",
    path: "/blog/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog-slug___es",
    path: "/es/blog/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog___pt",
    path: "/blog",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___es",
    path: "/es/blog",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "clinicas-slug___pt",
    path: "/clinicas/:slug()",
    meta: _91slug_93UjUuS3hKf9Meta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "clinicas-slug___es",
    path: "/es/clinicas/:slug()",
    meta: _91slug_93UjUuS3hKf9Meta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/[slug].vue").then(m => m.default || m)
  },
  {
    name: "clinicas___pt",
    path: "/clinicas",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/index.vue").then(m => m.default || m)
  },
  {
    name: "clinicas___es",
    path: "/es/clinicas",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/clinicas/index.vue").then(m => m.default || m)
  },
  {
    name: "contato___pt",
    path: "/contato",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "contato___es",
    path: "/es/contato",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/contato.vue").then(m => m.default || m)
  },
  {
    name: "franquia___pt",
    path: "/franquia",
    meta: franquia752DSa3noQMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/franquia.vue").then(m => m.default || m)
  },
  {
    name: "franquia___es",
    path: "/es/franquia",
    meta: franquia752DSa3noQMeta || {},
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/franquia.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "indique-e-ganhe-slug___pt",
    path: "/indique-e-ganhe/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/[slug].vue").then(m => m.default || m)
  },
  {
    name: "indique-e-ganhe-slug___es",
    path: "/es/indique-e-ganhe/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/[slug].vue").then(m => m.default || m)
  },
  {
    name: "indique-e-ganhe___pt",
    path: "/indique-e-ganhe",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/index.vue").then(m => m.default || m)
  },
  {
    name: "indique-e-ganhe___es",
    path: "/es/indique-e-ganhe",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/indique-e-ganhe/index.vue").then(m => m.default || m)
  },
  {
    name: "privacidade___pt",
    path: "/privacidade",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/privacidade.vue").then(m => m.default || m)
  },
  {
    name: "privacidade___es",
    path: "/es/privacidade",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/privacidade.vue").then(m => m.default || m)
  },
  {
    name: "sobre___pt",
    path: "/sobre",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/sobre.vue").then(m => m.default || m)
  },
  {
    name: "sobre___es",
    path: "/es/sobre",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/sobre.vue").then(m => m.default || m)
  },
  {
    name: "tratamentos-slug___pt",
    path: "/tratamentos/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tratamentos-slug___es",
    path: "/es/tratamentos/:slug()",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/[slug].vue").then(m => m.default || m)
  },
  {
    name: "tratamentos___pt",
    path: "/tratamentos",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/index.vue").then(m => m.default || m)
  },
  {
    name: "tratamentos___es",
    path: "/es/tratamentos",
    component: () => import("C:/Users/Gabriel.Fritsch/projetos/site-sorrifacil/pages/tratamentos/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/saiba-mais-site-v1",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/alegrete-odontologia",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/aracaju",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/assis",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/bage",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/barueri",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/bento-goncalves",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/blumenau",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/brusque",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/cachoeirinha",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/camaqua",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/cambe",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/campina-grande",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/campinas",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/campo-bom",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/canela",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/cangucu",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/canoas-mathias-velho",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/canoas-marechal-rondon",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/capao-da-canoa",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/carlos-barbosa",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/caruaru",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/caxias-do-sul",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/chapeco",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/cruz-alta",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/curitiba",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/d-pedrito",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/erechim",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/estancia-velha",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/esteio",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/florianopolis-mauro-ramos",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/florianopolis-ingleses",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/fortaleza",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/foz-do-iguacu",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/goiania",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/gramado",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/gravatai",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/guaiba",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/ibipora",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/igrejinha",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/indaiatuba",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/itapema",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/itaqui",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/itumbiara",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/jaguarao",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/jaguariuna",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/joao-pessoa",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/juazeiro-do-norte",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/lajeado",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/londrina-avenida-bandeirantes",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/londrina-benjamin-constant",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/maceio",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/maringa-avenida-sao-paulo",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/maringa-souza-naves",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/mogi-das-cruzes",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/montenegro",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/novo-hamburgo-centro",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/novo-hamburgo-patria-nova",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/osorio",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/panambi",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/parnamirim",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/parobe",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/passo-fundo",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/paulinia",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/marechal-floriano",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/pelotas-general-osorio",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/pelotas-andrade-neves",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/petrolina",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/piracicaba",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/ponta-grossa",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/portao",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/poa-rio-branco",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/poa-menino-deus",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/poa-otto-niemeyer",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/poa-tres-figueiras",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/porto-velho",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/presidente-prudente",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/rio-grande-general-bacelar",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/rio-grande-doutor-nascimento",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/rolandia",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santa-cruz-do-sul",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sta-maria-rua-acampamento",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sta-maria-saldanha-marinho",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santa-rosa",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santana-do-livramento",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santiago",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santo-angelo",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/santo-antonio-da-patrulha",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sao-bernardo-do-campo",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sao-gabriel",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sao-jose",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sao-leopoldo",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sao-lourenco-do-sul",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sapiranga",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/sapucaia-do-sul",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/taquara",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/teresina-centro",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/joquei-clube",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/torres",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/tramandai",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/tubarao",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/uberlandia",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/uruguaiana",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/valinhos",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/viamao",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/vitoria",
    component: component_45stubjibNALK6J9
  },
  {
    name: component_45stubjibNALK6J9Meta?.name,
    path: "/vitoria-da-conquista",
    component: component_45stubjibNALK6J9
  }
]